import { createSlice } from "@reduxjs/toolkit";

export const userSlice = createSlice({
  name: "user",
  initialState: {},
  reducers: {
    initData: (state) => {
      var token = sessionStorage.getItem("token");
      var userInfo = sessionStorage.getItem("userInfo");
      if (token && userInfo) {
        state.userInfo = JSON.parse(userInfo);
        state.token = token;
      }
    },
    loginIn: (state, params) => {
      const { token } = params.payload;
      state.token = token;
      state.userInfo = { ...params.payload, time: +new Date() };
      //本地缓存
      sessionStorage.setItem("token", token);
      sessionStorage.setItem("userInfo", JSON.stringify({...params.payload}));
    },
    loginOut: (state) => {
      state.token = null;
      state.userInfo = null;
      //本地缓存
      sessionStorage.clear();
    },
  },
});

export const { initData, loginIn, loginOut } = userSlice.actions;

export default userSlice.reducer;
