import { configureStore } from "@reduxjs/toolkit";
import userReducer from "./userSlice";
import configReducer from "./configSlice";
import routerReducer from "./routerSlice";

export default configureStore({
  reducer: {
    user: userReducer,
    config: configReducer,
    router: routerReducer,
  },
  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware({
      serializableCheck: false,
    });
  },
});
