import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Breadcrumb } from "antd";
import moment from "moment";
import "./index.scss";

function App(props) {
  const [navs, setNav] = useState([]);
  const [timer, setTimer] = useState(null);

  const setTime = () => {
    setTimer(moment().format("YYYY年M月D日 HH:mm:ss"));
    setTimeout(setTime, 1000);
  };
  const setRoutes = (route) => {
    const routes = [];
    if (!route || !route.pathname) return;
    let nowRoute = { ...route };
    routes.push(nowRoute);
    while (nowRoute.parent) {
      nowRoute = { ...nowRoute.parent };
      routes.push(nowRoute);
    }
    var navArr = [];
    routes.reverse().forEach((item, index) => {
      navArr.push({
        href:
          item.component && index < routes.length - 1 ? item.pathname : null,
        title: (
          <span>
            {item.icon}
            <label style={{ paddingLeft: "5px" }}>{item.label}</label>
          </span>
        ),
      });
    });
    setNav(navArr);
  };

  useEffect(() => {
    setTime();
    setRoutes(props.store.router.router);
    // eslint-disable-next-line
  }, [props.store.router.router]);

  return (
    <div className="app-header">
      <a className="logo" href="/login">Game Mamager</a>
      <div className="other">
        <div className="nav">
          <Breadcrumb items={navs} />
        </div>
        <div></div>
        <div className="times">{timer}</div>
      </div>
    </div>
  );
}
export default connect((store) => ({ store }))(App);
