import React, { useEffect, Suspense, lazy, useState } from "react";
import { BrowserRouter, Routes, Route, useNavigate } from "react-router-dom";
import { Button, Spin } from "antd";
import { initData } from "./redux/userSlice";
import { initConfig } from "./redux/configSlice";
import { initRouters } from "./routers";
import { setAuths } from "./redux/routerSlice";
import { connect, useDispatch } from "react-redux";
import RouterChange from "./redux/routerchange";
import "./App.scss";

import LoginPage from "./pages/login";
import HomePage from "./pages/main";
import Util from "./lib/util";
import HTTP from "./lib/server";

function App(props) {
  const dispatch = useDispatch();
  const { router, user, config } = props.store;
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    if (config.done === false) {
      dispatch(initData());
      dispatch(initConfig());
    }
    if (user.token && loading === false) {
      getMenuAuth();
    }
    // eslint-disable-next-line
  }, [user.userInfo]);

  const getMenuAuth = async () => {
    setLoading(true);
    var res = await HTTP._getAuth();
    const { routerAuthMaps, routerAuthList, routerArray } = initRouters(res.data || []);
    dispatch(
      setAuths({
        routers: routerAuthList,
        routerMaps: routerAuthMaps,
        menus: [...routerArray],
      })
    );
  };

  return (
    <div className="my-app">
      <BrowserRouter>
        <RouterChange />
        <Routes>
          <Route path="/" element={<HomePage />}>
            {router.routerMaps &&
              router.routerMaps.map((item) => {
                const Pagebuild = lazy(async () => {
                  await Util.sleep(100);
                  return import(`${item.component}`);
                });
                return (
                  <Route
                    key={item.key}
                    path={item.pathname}
                    element={
                      <Suspense fallback={<LoadingPage />}>
                        <div className="animated fadeIn">
                          <Pagebuild />
                        </div>
                      </Suspense>
                    }
                  />
                );
              })}
          </Route>
          <Route path="/login" element={<LoginPage />} />
          <Route path="/*" element={<LoadingCtrl />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default connect((state) => ({ store: state }))(App);

function LoadingCtrl() {
  const navigate = useNavigate();
  return (
    <div className="page-404">
      <Button type="dashed" size="large" onClick={() => navigate("/login")}>
        立即返回
      </Button>
    </div>
  );
}

function LoadingPage() {
  return (
    <div className="page-parent">
      <Spin tip="Loading...">
        <div
          className="page-content"
          style={{
            minHeight: "calc(100vh - 100px)",
          }}
        />
      </Spin>
    </div>
  );
}
