import { createSlice } from "@reduxjs/toolkit";

export const routerSlice = createSlice({
  name: "router",
  initialState: {
    menus: [],
    routers: [],
    routerMaps: [],
    router: null,
  },
  reducers: {
    setAuths: (state, param) => {
      state.routers = param.payload.routers;
      state.routerMaps = param.payload.routerMaps;
      state.menus = param.payload.menus;
    },
    setRouter: (state, param) => {
      state.router = param.payload;
    },
  },
});

export const { setAuths, setRouter } = routerSlice.actions;

export default routerSlice.reducer;
