import React, {useEffect, useState} from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Menu } from "antd";

function App(props) {
  const navigate = useNavigate();
  const { menus, store: { router } } = props;
  const [menuData, setMenuData] = useState([]);
  const menuItemClick = (item) => {
    var r = router.routerMaps.filter(
      (m) => m.key.toString() === item.key.toString()
    );
    if (r.length > 0) {
      navigate(r[0].pathname);
    }
  };
  const hiddenmenus = (items) => {
    var children = [];
    items.forEach((item) => {
      if(!item.hidden){
        var newItem = {...item, children: []};
        if(item.children && item.children.length > 0){
          newItem.children = hiddenmenus(item.children);
        }
        if(newItem.children.length === 0){
          newItem.children = null;
        }
        children.push(newItem);
      }
    });
    return children;
  }
  useEffect(() => {
    var n = hiddenmenus(menus);
    setMenuData(n);
    // eslint-disable-next-line
  }, [menus]);
  return (
    <div className="app-menus">
      <Menu
        mode="inline"
        style={{ width: 230 }}
        defaultOpenKeys={[]}
        selectedKeys={router.router ? [router.router.key.toString()] : []}
        items={menuData}
        onClick={menuItemClick}
      />
    </div>
  );
}

export default connect((state) => ({ store: state }))(App);
