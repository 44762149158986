import axios from "axios";
import store from "../redux/index";

const request = axios.create();

request.interceptors.request.use((req) => {
  const { config } = store.getState();
  const { ctxPath, appKey, clientType, version } = config;
  req.headers["Content-Type"] = "application/json; charset=UTF-8";
  var token = sessionStorage.getItem("token");
  req.headers["Authorization"] = token;
  req.baseURL = ctxPath;
  if(!req.data) req.data = {};
  req.data._appKey = appKey;
  req.data._clientType = clientType;
  req.data._version = version;
  req.data._channel = "10000";
  req.data._timestamp = +new Date();
  return req;
});

request.interceptors.response.use(
  (res) => {
    if (res.data.code === 401) {
      sessionStorage.removeItem("token");
      sessionStorage.removeItem("userInfo");
      // window.location.href = "/login";
    }
    return res.data;
  },
  (error) => {
    const { code, message } = error.toJSON();
    return { code: code || 500, message: message || "服务异常" };
  }
);

export default request;
