import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import AppRoot from "./App";
import { Provider } from "react-redux";
import { ConfigProvider } from "antd";
import moment from "moment";
import zhcn from "antd/locale/zh_CN";
import store from "./redux/index";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    <ConfigProvider
      locale={zhcn}
      theme={{
        token: {
          colorBgContainer: "#f6ffed",
          colorPrimary: "#00b96b",
          borderRadius: 2,
        },
      }}
    >
      <AppRoot />
    </ConfigProvider>
  </Provider>
);

moment.locale("zh-cn");
