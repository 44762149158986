import React, { useState } from "react";
import { LockOutlined, UserOutlined } from "@ant-design/icons";
import { Button, Form, Input, App } from "antd";
import { useNavigate } from "react-router-dom";
import { loginIn } from "../../redux/userSlice";
import { initRouters } from "../../routers";
import { setAuths } from "../../redux/routerSlice";
import { useDispatch } from "react-redux";
import HTTP from "../../lib/server";
import Util from "../../lib/util";
import "./index.scss";

function AppPage() {
  const { message } = App.useApp();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const onFinish = async (values) => {
    setLoading(true);
    let res = await HTTP._login(values);
    if (res.code === 200) {
      sessionStorage.setItem("account", values.account);
      sessionStorage.setItem("password", values.password);
      dispatch(loginIn(res.data));
      getMenuAuth();
      await Util.sleep(1000);
      navigate("/");
    } else {
      message.error(res.message);
    }
    setLoading(false);
  };
  const getMenuAuth = async () => {
    setLoading(true);
    var res = await HTTP._getAuth();
    const { routerAuthMaps, routerAuthList, routerArray } = initRouters(res.data || []);
    dispatch(
      setAuths({
        routers: routerAuthList,
        routerMaps: routerAuthMaps,
        menus: routerArray,
      })
    );
  };

  return (
    <div className="login-page">
      <div className="login-form">
        <Form
          form={form}
          name="horizontal_login"
          layout="block"
          onFinish={onFinish}
          initialValues={{
            account: sessionStorage.getItem("account"),
            password: sessionStorage.getItem("password")
          }}
        >
          <div className="icon"></div>
          <div className="title">Game data management Center</div>
          <Form.Item name="account" rules={[{ required: true }]}>
            <Input
              size="large"
              prefix={<UserOutlined className="site-form-item-icon" />}
              placeholder="用户名"
              autoComplete="off"
            />
          </Form.Item>
          <Form.Item name="password" rules={[{ required: true }]}>
            <Input
              size="large"
              prefix={<LockOutlined className="site-form-item-icon" />}
              type="password"
              placeholder="密码"
              autoComplete="off"
            />
          </Form.Item>
          <Form.Item shouldUpdate>
            {() => (
              <Button
                size="large"
                type="primary"
                htmlType="submit"
                block
                loading={loading}
              >
                Login In
              </Button>
            )}
          </Form.Item>
        </Form>
      </div>
    </div>
  );
}

export default React.FC = () => (
  <App>
    <AppPage />
  </App>
);
