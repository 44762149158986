import HeaderTmpl from "../../component/header";
import MenusTmpl from "../../component/menus";
import { connect } from "react-redux";
import { Outlet, useNavigate } from "react-router-dom";
import "./index.scss";
import { useEffect } from "react";

function App(props) {
  const { routers } = props.store.router;
  const navigate = useNavigate();
  useEffect(() => {
    if (!sessionStorage.getItem("token")) {
      navigate("/login");
    }
    // eslint-disable-next-line
  }, []);

  return (
    <div className="main-router">
      <div className="layout-left">
        <MenusTmpl menus={routers} />
      </div>
      <div className="layout-header">
        <HeaderTmpl />
      </div>
      <div className="layout-content">
        <div className="layout-body">
          <Outlet />
        </div>
      </div>
    </div>
  );
}

export default connect((state) => ({ store: state }))(App);
