import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import { setRouter } from "./routerSlice";

function App(props) {
  const location = useLocation();
  const dispatch = useDispatch();
  const { router } = props.store;
  useEffect(() => {
    if (
      router.router === null ||
      router.router.pathname !== location.pathname
    ) {
      var selRouter = router.routerMaps.filter(
        (r) => r.pathname === location.pathname
      );
      if (selRouter[0]) {
        dispatch(setRouter({ ...selRouter[0] }));
        document.title = selRouter[0].label;
      }
    }
    // eslint-disable-next-line
  }, [location]);
  return null;
}

export default connect((state) => ({ store: state }))(App);
